<template>
  <div class="add-new-holiday">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.vendorCreate')"
      :title="$t('messages.vendorCreate')"
      @submitPressed="submitPressed"
    />
    <vendor-form :vendorObject="vendor" :action="action" @clearAction="action = null"/>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import VendorForm from './components/VendorForm.vue'

export default {
  components: {
    TitleBar,
    VendorForm
  },
  data() {
    return {
      action: null,
      vendor: {
        address: {}
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
